import React, { useEffect, useState } from "react";
import "./Table.css";
import DropDown from "../Input/DropDown/DropDown";
import TableList from "./TableList";
export default function Table() {
  const [tableData, setTableData] = useState([])
  const [array, setArray] = useState([]);
  const [input, setInputdata] = useState({
    name: "",
    description: "",
    githubUrl: "",
    status: "",
    category: "",
    frontend: "",
    backend: "",
    leader: "",
  });
  const [loading, setLoading] = useState(false); // Removed extra backticks
  const [index, setIndex] = useState();
  const [bolin, setBolin] = useState(false);
  const [newData, setNewData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [backend, setBackend] = useState([]);
  const [frontend, setFrontend] = useState([]);
  const [leader, setLeader] = useState([]);
  const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);
  const [newText, setNewText] = useState("");

  const APIkey = localStorage.getItem("token");
  const openDescriptionPopup = () => {
    setShowDescriptionPopup(true);
  };

  const closeDescriptionPopup = () => {
    setShowDescriptionPopup(false);
  };

  const handleTextChange = (event) => {
    setNewText(event.target.value);
  };
  function data(e) {
    setInputdata({ ...input, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    fetchData();
  }, [APIkey]);

  const fetchData = async () => {
    await Promise.all([
      getCategory(),
      getProgrammingLanguages("backend", setBackend),
      getProgrammingLanguages("frontend", setFrontend),
      getLeaders(),
      getTableData(),
      
    ]);
  };

  const getTableData = async () => {
    try {
      const response = await fetch("http://13.232.109.10/api/projects", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${APIkey}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const tableServerData = await response.json();
        setTableData(tableServerData);
        console.log('tabledata:::>>',tableServerData)
      } else {
        throw new Error("Failed to fetch data from the server");
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };


  const getCategory = async () => {
    try {
      const response = await fetch("http://13.232.109.10/api/category", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${APIkey}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        throw new Error("Failed to fetch categories from the server");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getProgrammingLanguages = async (stack, setState) => {
    try {
      const response = await fetch(
        `http://13.232.109.10/api/programmingLanguage?stack=${stack}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${APIkey}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setState(data);
      } else {
        throw new Error(
          `Failed to fetch ${stack} programming languages from the server`
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getLeaders = async () => {
    try {
      const response = await fetch(
        "http://13.232.109.10/api/user/getUserByRole?role",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${APIkey}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setLeader(data);
      } else {
        throw new Error("Failed to fetch leaders from the server");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const addInputData = async () => {
    try {
      if (!input.name) {
        alert("Name is required");
        return;
      }

      setLoading(true);

      const apiUrl = "http://13.232.109.10/api/projects";
      const headers = {
        Authorization: `Bearer ${APIkey}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(input),
      });

      if (response.ok) {
        const responseData = await response.json();
        setNewData(responseData);

        setArray([...array, input]);
        setInputdata({
          name: "",
          description: "",
          githubUrl: "",
          status: "",
          category: "",
          frontend: "",
          backend: "",
          leader: "",
        });
      } else {
        throw new Error("Failed to add data to the server");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(true); // Hide loading indicator
    }
  };

  const upDateArray = (i) => {
    setInputdata(array[i]);
    setBolin(true);
    setIndex(i);
  };

  const deleteData = (i) => {
    let totalArr = [...array];
    totalArr.splice(i, 1);
    setArray(totalArr);
  };

  const updateInfo = () => {
    let totalArr = [...array];
    totalArr.splice(index, 1, input);
    setArray(totalArr);
    setBolin(false);
  };

  const options = ["Open", "Pending", "Completed"];

  return (
    <div className="input-fileed">
      <div className="input-fide-type">
        <input
          required={true}
          type="text"
          value={input.name}
          name="name"
          autoCapitalize="off"
          placeholder="name"
          onChange={data}
        />
        <input
          type="text"
          value={input.description}
          name="description"
          autoCapitalize="off"
          placeholder="description"
          onChange={data}
        />
        <input
          type="text"
          value={input.githubUrl}
          name="githubUrl"
          autoCapitalize="off"
          placeholder="gitHubUrl"
          onChange={data}
        />
        <div className="dropdon-element">
          <select name="status" value={input.status} onChange={data}>
            <option value="">Select a status</option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
                {console.log("option name", option)}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdon-element">
          <select name="category" value={input.category} onChange={data}>
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.uuid} value={category.uuid}>
                {category.name}
                {console.log("category name", category.name)}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdon-element">
          <select name="frontend" value={input.frontend} onChange={data}>
            <option value="">Select a frontend</option>
            {frontend.map((frontend) => (
              <option key={frontend.uuid} value={frontend.uuid}>
                {frontend.name}
                {console.log("category name", frontend.name)}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdon-element">
          <select name="backend" value={input.backend} onChange={data}>
            <option value="">Select a backend</option>
            {backend.map((backend) => (
              <option key={backend.uuid} value={backend.uuid}>
                {backend.name}
                {console.log("category name", backend.name)}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdon-element">
          <select name="leader" value={input.leader} onChange={data}>
            <option value="">leader</option>
            {leader.map((leader) => (
              <option key={leader.uuid} value={leader.uuid}>
                {leader.firstName + " " + leader.lastName}
                {console.log("category name", leader.name)}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" onClick={!bolin ? addInputData : updateInfo}>
          {!bolin ? `Add data` : `update data`}
        </button>
        <br />
      </div>
      {/* <div>
        <TableList/>
      </div> */}
      {/* <div className="table-data">
        {loading ?(<p>Loading...</p>):(<table
          style={{
              width: "100%",
              margin: "10px 0px",
              padding: "10px 0px",
              border: "5px solid #ccc",
              width: "100%",
              borderCollapse: "collapse",
          }}
        >
          <thead style={{ borderBottom: "2px solid #ccc" }}>
            <tr border="10" style={{ height: 50 }}>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                Name
              </th>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                Description
              </th>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                GitHub URL
              </th>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                Status
              </th>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                Category
              </th>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                Frontend
              </th>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                Backend
              </th>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                Leader
              </th>
              <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                Update
              </th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {array.map((item, index) => {
              return (
                <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
                  <td>{item.name}</td>
                  <td>
                    {showDescriptionPopup ? (
                      <div>
                        {item.description}
                        <br />
                        <textarea value={newText} onChange={handleTextChange} />
                      </div>
                    ) : (
                      <button onClick={openDescriptionPopup}>
                        Show Description
                      </button>
                    )}
                  </td>
                  <td>
                    <a
                      href={item.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.githubUrl}
                    </a>
                  </td>
                  <td>{item.status}</td>
                  <td>
                    {categories.find((c) => c.uuid === item.category).name}
                  </td>
                  <td>{frontend.find((f) => f.uuid === item.frontend).name}</td>
                  <td>{backend.find((b) => b.uuid === item.backend).name}</td>
                  <td>{leader.find((l) => l.uuid === item.leader).role}</td>
                  <td>
                    <button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => upDateArray(index)}
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteData(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>)}
        
      </div> */}
    </div>
  );
}
