import React, { useEffect, useState } from "react";
// import "./AddProject.css";
import TwoButton from "../../Twobutton/TwoButton";
import Table from "../../../Table/Table";
import TableList from "../../../Table/TableList";
export default function AddProject() {
  const [showForm, hideForm] = useState(false);
  useEffect(() => {
    hideForm(!showForm);
  }, []);
  const [data, setData] = useState([]);
  const handleAddData = (newData) => {
    setData([...data, newData]);
    hideForm(false);
  };
  return (
    <div>
      <TwoButton
        textName="Add Project"
        buttonName="Add Project"
        onClick={() => {
          hideForm(!showForm);
        }}
      />
      {showForm && <Table/>}
     <TableList/>
    </div>
  );
}
