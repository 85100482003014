import React from "react";
import TwoButton from "../../Twobutton/TwoButton";
import Cardd from "../../ExtraElement/Cardd";
import {GrLinkNext} from 'react-icons/gr'
import "./Dashboard.css";
export default function Dashboard() {
  return (
    <div>
      <TwoButton textName="Overview" buttonName="click me" />
      <div className="card-contener">
        <div className="card-one">
          <div className="card-element">
            <img height={60} width={60} 
              src="https://material-kit-pro-react.devias.io/assets/iconly/iconly-glass-tick.svg"
              alt="icone-image"
            />
            <div className="static-value">
              <p className="ppp">Done Tasks</p>
              <p className="pp">number</p>
            </div>
          </div>
          <div className="button-and-line"> <button>click me <GrLinkNext/></button></div>
        </div>
        <div className="card-one">
          <div className="card-element">
            <img height={60} width={60} 
              src="https://material-kit-pro-react.devias.io/assets/iconly/iconly-glass-info.svg"
              alt="icone-image"
            />
            <div className="static-value">
              <p className="ppp">Pending Issues</p>
              <p className="pp">12</p>
            </div>
          </div>
          <div className="button-and-line"> <button>click me <GrLinkNext/></button></div>
        </div>
        <div className="card-one">
          <div className="card-element">
            <img height={60} width={60} 
              src="https://material-kit-pro-react.devias.io/assets/iconly/iconly-glass-tick.svg"
              alt="icone-image"
            />
            <div className="static-value">
              <p className="ppp">Open tickets</p>
              <p className="pp">number</p>
            </div>
          </div>
          <div className="button-and-line"> <button>click me <GrLinkNext/></button></div>
        </div>
        <div className="card-one">
          <div className="card-element">
            <img height={60} width={60} 
              src="https://material-kit-pro-react.devias.io/assets/iconly/iconly-glass-paper.svg"
              alt="icone-image"
            />
            <div className="static-value">
              <p className="ppp">Add project</p>
              <p className="pp">number</p>
            </div>
          </div>
          <div className="button-and-line"> <button>click me <GrLinkNext/></button></div>
        </div>
      </div>
    </div>
  );
}