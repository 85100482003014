import React, { useState } from "react";
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList,
} from "react-icons/fa";
import {AiOutlineFolderAdd} from "react-icons/ai"
import "./Sidebar.css";
import { NavLink } from "react-router-dom";

const Sidebar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: <FaTh />,
        },
        {
            path: "/addProject",
            name: "AddProject",
            icon: <AiOutlineFolderAdd />,
        },
        {
            path: "/about",
            name: "About",
            icon: <FaUserAlt />,
        },
        {
            path: "/analytics",
            name: "Analytics",
            icon: <FaRegChartBar />,
        },
        {
            path: "/comment",
            name: "Comment",
            icon: <FaCommentAlt />,
        },
        {
            path: "/product",
            name: "Product",
            icon: <FaShoppingBag />,
        },
    ];
    const [menuItems, setMenuItems] = useState([
        {
            label: "Item 1",
            path: "",
            subItems: [
                { label1: 'Sub-Item 1', path: '/subitem1' },
                { label1: 'Sub-Item 1.2', path: '/subitem2' },
                { label1: 'Sub-Item 1.3', path: '/subitem3' },
                { label1: 'Sub-Item 1.4', path: '/subitem4' },
            ],
            isOpenn: false,
            icon: <FaTh />,
        },
        {
            label: "Item 2",
            path: "",
            subItems: [
                { label1: 'Sub-Item 2.1', path: '/subitem1' },
                { label1: 'Sub-Item 2.2', path: '/subitem2' },
                { label1: 'Sub-Item 2.3', path: '/subitem3' },
                { label1: 'Sub-Item 2.4', path: '/subitem4' },
            ],
            isOpenn: false,
            icon: <FaTh />,
        },
        {
            label: "Item 3",
            path: "",
            subItems: [
                { label1: 'Sub-Item 3.1', path: '/subitem1' },
                { label1: 'Sub-Item 3.2', path: '/subitem2' },
                { label1: 'Sub-Item 3.3', path: '/subitem3' },
                { label1: 'Sub-Item 3.4', path: '/subitem4' },
            ],
            isOpenn: false,
            icon: <FaTh />,
        },
        {
            label: "Item 4",
            path: "",
            subItems: [
                { label1: 'Sub-Item 4.1', path: '/subitem1' },
                { label1: 'Sub-Item 4.2', path: '/subitem2' },
                { label1: 'Sub-Item 4.3', path: '/subitem3' },
                { label1: 'Sub-Item 4.4', path: '/subitem4' },
            ],
            isOpenn: false,
            icon: <FaTh />,
        },
    ]);

    const toggleSubMenu = (index) => {
        const updatedMenuItems = [...menuItems];
        updatedMenuItems[index].isOpenn = !updatedMenuItems[index].isOpenn;
        setMenuItems(updatedMenuItems);
    };
    return (
        <div className="container">
            <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
                <div className="top_section">
                    <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
                        Logo
                    </h1>
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                        <FaBars onClick={toggle} />
                    </div>
                </div>
                {menuItem.map((item, index) => (
                    <NavLink
                        to={item.path}
                        key={index}
                        className="link"
                        activeclassname="active"
                    >
                        <div className="icon">{item.icon}</div>
                        <div
                            style={{ display: isOpen ? "block" : "none" }}
                            className="link_text"
                        >
                            {item.name}
                        </div>
                    </NavLink>
                ))}
                <div>
                    <ul className="dropdon-and-element">
                        {menuItems.map((item, index) => (
                            <li key={index}>
                                <div
                                    className={`menu-item ${item.isOpenn ? "open" : ""}`}
                                    onClick={() => toggleSubMenu(index)}
                                >
                                    <NavLink to={item.path}>
                                        <div className="icon">{item.icon}</div>
                                        <div
                                            style={{ display: isOpen ? "block" : "none" }}
                                            className="link_text"
                                        >
                                            <p>{item.label}</p>
                                        </div>
                                    </NavLink>
                                </div>
                                {item.isOpenn && (
                                    <ul className="sub-menu">
                                        {item.subItems.map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                <div
                                                    className={`menu-item ${item.isOpenn ? "open" : ""}`}
                                                >
                                                    <NavLink
                                                        to={subItem.path}
                                                        className="link"
                                                        activeclassname="active"
                                                    >
                                                        <div
                                                            style={{ display: isOpen ? "block" : "none" }}
                                                            className="link_text"
                                                        >
                                                            
                                                        </div>
                                                        {subItem.label1}
                                                    </NavLink>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;
