import React from 'react'
import TwoButton from '../../Twobutton/TwoButton'

export default function ProductList() {
  return (
    <div>
      <TwoButton textName='Ankur sigh' buttonName='click me' />
      <div>ProductList</div>
    </div>
  )
}
