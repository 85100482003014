import React, { useState, useEffect } from "react";
import { BiUserCircle } from "react-icons/bi";
import "./TableList.css";
const TableList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ description: "" });
  const [isaddNote, setisAddnote] = useState(false);
  const [addNote, setAddnote] = useState();
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState(null);
  const [comment, setComment] = useState("");
  const [selectedProjectUUID, setSelectedProjectUUID] = useState(null);
  const [showComment, setShowComment] = useState("");
  const [messages, setMessages] = useState([]);

  const APIkey = localStorage.getItem("token");
  useEffect(() => {
    getProject();
    handleSubmitComment();
    getComment();
  }, [selectedProjectUUID]);

  const getProject = () => {
    fetch("http://13.232.109.10/api/projects", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${APIkey}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
  const handleProjectClick = (item) => {
    setSelectedProjectUUID(item.uuid);
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleOpenPopup = (item) => {
    setIsPopupOpen(true);
    setSelectedItem(item);
  };

  const handleOpenAddNote = (item) => {
    setisAddnote(true);
    setAddnote(item);
    const selectedProjectUUID = item.uuid;
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setisAddnote(false);
  };

  const handleUpdateDescription = () => {
    const updatedData = data.map((item) =>
      item.uuid === selectedItem.uuid
        ? { ...item, description: selectedItem }
        : item
    );
  };
  const getComment = () => {
    if (!selectedProjectUUID) {
      return;
    }
    fetch(
      `http://13.232.109.10/api/comment?project=${selectedProjectUUID}&page=0&size=10`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${APIkey}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
          // console.log('comment responce',response.json());
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .then((data) => {
        setShowComment(data);
        setMessages(data);
        setLoading(false);
        console.log("this is new masg data", data);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSubmitComment = () => {
    if (isSending) {
      return;
    }

    const commentData = {
      data: comment,
      projects: selectedProjectUUID,
    };
    fetch("http://13.232.109.10/api/comment", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${APIkey}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(commentData),
    })
      .then((response) => {
        if (response.ok) {
          setIsSent(true);
          setComment("");
        } else {
          setIsSending(false);
          setError("Failed to send the comment. Please try again.");
        }
      })
      .catch((error) => {
        setIsSending(false);
        setError("An error occurred while sending the comment.");
      });
    handleClosePopup();
  };
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error.message}</p>;
  }
  return (
    <div>
      <div className="table-data">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table
            style={{
              width: "100%",
              margin: "10px 0px",
              padding: "10px 0px",
              border: "5px solid #ccc",
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead style={{ borderBottom: "2px solid #ccc" }}>
              <tr border="10" style={{ height: 50 }}>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  Name
                </th>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  Description
                </th>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  GitHub URL
                </th>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  Status
                </th>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  Category
                </th>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  Frontend
                </th>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  Backend
                </th>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  Leader
                </th>
                <th style={{ borderRight: "2px solid #ccc", width: "10%" }}>
                  comment
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr
                    key={item.uuid}
                    style={{ borderBottom: "1px solid #ccc" }}
                    onClick={() => handleProjectClick(item)}
                  >
                    <td>{item.name}</td>
                    <td>
                      <button id="z" onClick={() => handleOpenPopup(item)}>
                        Edit Description
                      </button>
                    </td>
                    <td>{item.githubUrl}</td>
                    <td>{item.status}</td>
                    <td>{item.category ? item.category.name : "N/A"}</td>
                    <td>{item.frontend ? item.frontend.name : "N/A"}</td>
                    <td>{item.backend ? item.backend.name : "N/A"}</td>
                    <td>{item.leader ? item.leader.role : "N/A"}</td>
                    <td>
                      <button id="z" onClick={() => handleOpenAddNote(item)}>
                        Add note
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {isPopupOpen && (
          <div className="overlay">
            <div className="popup">
              {/* Your popup content */}
              <textarea
                value={selectedItem.description}
                onChange={(e) =>
                  setSelectedItem({ description: e.target.value })
                }
              />
              <div className="Buttonn">
                <button
                  onClick={() =>
                    handleUpdateDescription(selectedItem.description)
                  }
                >
                  Update
                </button>
                <button onClick={handleClosePopup}>Close</button>
              </div>
            </div>
          </div>
        )}

        {isaddNote && (
          <div className="note-overlay">
            <div className="note-popup">
              <textarea
                value={comment}
                onChange={handleCommentChange}
                placeholder="Enter your comment..."
                rows={4}
              />
              {error && <p>Error: {error}</p>}
              {/* <div className="comment-box">
                <ul>
                  {isaddNote &&
                    messages.map((comment, index) => {
                      return (
                        <div className="coment-list">
                          <div className="icon-name">
                          <BiUserCircle/>
                            <p></p>
                          </div>
                          <li id="comment-list" key={index}>
                            {comment.data}
                          </li>
                        </div>
                      );
                    })}
                </ul>
              </div> */}
             <div className="comment-box">
  <ul>
    {isaddNote &&
      messages.map((comment, index) => {
        // Check if comment.data has a length greater than 1
        if (comment.data.length > 0) {
          return (
            <div className="coment-list" key={index}>
              <div className="icon-name">
                <BiUserCircle />
                <p></p>
              </div>
              <li id="comment-list">
                {comment.data}
              </li>
            </div>
          );
        } else {
          // Return a non-classed div if the condition is not met
          return (
            <div key={index}>
              {comment.data}
            </div>
          );
        }
      })}
  </ul>
</div>

              <div className="Buttonn">
                <button onClick={handleSubmitComment} disabled={isSending}>
                  {isSending ? "Sending..." : "Submit Comment"}
                </button>
                <button onClick={handleClosePopup}>Close</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableList;
