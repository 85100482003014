import React from 'react'
import TwoButton from '../../Twobutton/TwoButton'
export default function Product() {
  return (
    <div>
    <TwoButton textName='Ankur sigh' buttonName='click me' />
    <div>Product</div>
  </div>
  )
}
