import React from 'react'
import './TwoButton.css'
import { AiOutlinePlus } from 'react-icons/ai';

export default function TwoButton(props) {
  return (
    <div className='two-button'>
      <div className='text-heading'><p>{props.textName}</p></div>
      <div className='Button-common'><button onClick={props.onClick}><div className='plusicon'><AiOutlinePlus /></div>{props.buttonName}</button></div>
      
    </div>
  )
}
