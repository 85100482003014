import React from 'react'
import TwoButton from '../../Twobutton/TwoButton'
export default function Analytics() {
  return (
    <div>
      <TwoButton textName='Ankur sigh' buttonName='click me' />
      <div>Analytics</div>
    </div>
  )
}
