import React from 'react'
import './MainScreen.css'
import TopNavBar from '../TopNavbar/TopNavBar'
export default function MainScreen() {
  return (
    <div className='MainScreen-Class'>
    <TopNavBar/>
    </div>
  )
}
