import React from 'react'
import TwoButton from '../../Twobutton/TwoButton'
export default function Comment() {
  return (
    <div>
    <TwoButton textName='Ankur snigh' buttonName='click me' />
    <div>Comment</div>
  </div>
  )
}
