import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Routerr from "./Component/Router/Routerr";
import Login from "./Component/Login/Login";
import ForgotPassword from "./Component/ForgotPassword/ForgotPassword";
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(()=>{
    const storedLoginStatus =localStorage.getItem('isLoggedIn')
    if(storedLoginStatus=='true'){
      setIsLoggedIn(true);
    }
    else {
      setIsLoggedIn(false);
    }
  },[])
  const newhandleLogin = (isAuthenticated) => {
    setIsLoggedIn(isAuthenticated);
    localStorage.setItem('isLoggedIn', 'true');
    console.log("isAuthenticated::>>",isAuthenticated)
  };
  return (
    <BrowserRouter>
    
      {isLoggedIn ? (<Routerr />) :( <Routes>
          <Route
            path="/"
            element={<Login onLogin={newhandleLogin} />}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword />}
          />
        </Routes>)}
    </BrowserRouter>
  );
};

export default App;
