import React from 'react'
import { BrowserRouter, Route, Routes,useMatch } from 'react-router-dom';
import Dashboard from '../RightElement/OtherScreen/Pages/Dashboard'
import About from '../RightElement/OtherScreen/Pages/About'
import Comment from '../RightElement/OtherScreen/Pages/Comment'
import Analytics from '../RightElement/OtherScreen/Pages/Analytics'
import Product from '../RightElement/OtherScreen/Pages/Product'
import ProductList from '../RightElement/OtherScreen/Pages/ProductList'
import Sidebar from '../LeftNavBar/Sidebar';
import MainScreen from '../RightElement/MainScreen/MainScreen';
import AddProject from '../RightElement/OtherScreen/Pages/AddProject';

import Nomatch from '../Nomatch/Nomatch';
export default function Routerr() {
  return (
    <div>
         <Sidebar>
         <MainScreen/>
         <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/addProject" element={<AddProject />} />
          <Route path="/about" element={<About />} />
          <Route path="/comment" element={<Comment />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/product" element={<Product />} />
          <Route path="/productList" element={<ProductList />} />
          <Route path="*" element={<Nomatch />} />
        </Routes>
        </Sidebar>
       
      
    </div>
  )
}
