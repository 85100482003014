import React from 'react'
import TwoButton from '../../Twobutton/TwoButton'

export default function About(props) {
  return (
    <div>
      <TwoButton textName='Ankur sigh' buttonName='click me' />
      <div>about</div>
    </div>
  )
}
