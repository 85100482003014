import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import "./Login.css";
import jwt_decode from "jwt-decode";
import axios from "axios";
import {
  NavLink,
} from "react-router-dom";

function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(['token']);

  useEffect(() => {
    const savedUsername = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");

    if (savedUsername && savedPassword) {
      setEmail(savedUsername);
      setPassword(savedPassword);
    }
  }, []);

  const performLogin = async (response) => {
    const token = response.data.token;
    const decodedToken = jwt_decode(token);
    const expirationTime = decodedToken.exp * 1000;

    if (localStorage.getItem("tokenExpirationTimeout")) {
      clearTimeout(JSON.parse(localStorage.getItem("tokenExpirationTimeout")));
    }

    setCookie('token', token, { path: '/' });
    onLogin(true);
    setError("");
    localStorage.setItem("token", token);

    const tokenExpirationTimeout = setTimeout(() => {
      handleLogout();
    }, expirationTime - Date.now());

    localStorage.setItem("tokenExpirationTimeout", JSON.stringify(tokenExpirationTimeout));
  };

  const handleLogout = () => {
    setCookie('token', '', { path: '/', expires: new Date(0) });
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpirationTimeout');
    onLogin(false);
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://13.232.109.10/api/login", {
        email,
        password,
      });

      if (response.data && response.data.token) {
        performLogin(response);
      } else {
        setError("Incorrect username or password");
      }
    } catch (err) {
      setError("Invalid email or password");
      console.error(err);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form">
        <h4 className="login-text">Log in</h4>
        <div className="form-group">
          <label htmlFor="email" className="emile"></label>
          <div>
            {error && (
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            )}
            <p className="emailtxt"></p>
            <input
              type="email"
              id="email"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="password"></label>
          <input
            type="password"
            id="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit" onClick={handleLogin}>
          <p>Login</p>
        </button>
        <div style={{ flexDirection: 'row', display: "flex", marginTop: 20 }}>
          <div style={{ flex: .5 }}>Ankur singh</div>
          <div style={{ flex: .5 }}>
            <div className="forgot_password">
              <NavLink to="/forgot-password">About</NavLink>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
