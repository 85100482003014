import React, { useState } from "react";
import './ForgotPassword.css'
const ForgotPassword =()=>{
  console.log('forgot-passwordforgot-password')
  const [email,setEmail]= useState('')
  return(
    <div className="forget-container">
      {/* <h3 className='forget-text'>forget</h3> */}
      <form className="forget-form">
        <h4 className="forget-text">Send email</h4>
        <div className="form-group">
          <label htmlFor="email" className="emile"></label>
          <div>
            <p className="emailtxt"></p>
            <input
              type="email"
              id="forgot-password"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <button type="submit" onClick={()=>{console.log('ankur singh')}}>
          <p>Send email</p>
        </button>
      </form>
    </div>
  )
}
export default ForgotPassword;