import React,{useState} from "react";
import "./TopNavBar.css";
import { AiOutlineSearch } from "react-icons/ai";
export default function TopNavBar() {
  const [isHovered, setIsHovered] = useState(false);
  const [markersVisible, setMarkersVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setMarkersVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setTimeout(() => {
      if (!isHovered) {
        setMarkersVisible(false);
      }
    }, 300);
  };
  return (
    <div className="top-navbar">
      <div className="logo">
        <button 
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         className="serchLogo">
          <AiOutlineSearch />
        </button>
        {isHovered && <p id="search-text">Search</p>}
      </div>
      <div className="menu">
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Contact</li>
        </ul>
      </div>
    </div>
  );
}
